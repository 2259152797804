<template>
  <flat-pickr
    class="date-picker b-text-1"
    :class="{ error: error }"
    :model-value="state.date"
    :placeholder="placeholder"
    :config="{
      dateFormat: flatPickerFormat,
      enableTime: enableTime,
      noCalendar: noCalendar,
      minDate: minDate,
      minuteIncrement: minuteIncrement,
      time_24hr: time24hr,
      onOpen: (selectedDates, dateStr, instance) => {
        actions.openCalendar(instance);
      },
      onClose: () => {
        actions.closeCalendar();
      },
      onChange: (selectedDates) => {
        actions.updateDate(selectedDates);
      },
    }"
  ></flat-pickr>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { computed, reactive } from "vue";

export default {
  name: "InputDatePicker",
  components: { flatPickr },
  props: {
    date: {
      type: [Object, String], // moment Object
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    enableTime: {
      type: Boolean,
      default: true,
    },
    noCalendar: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      required: true,
    },
    minDate: {
      type: [Object, String],
      required: false,
    },
    minuteIncrement: {
      type: Number,
      default: 5,
    },
    error: {
      type: Boolean,
      default: false,
    },
    time24hr: {
      type: Boolean,
      default: false,
    },
    preventInputMinuteElement: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateDate", "openCalendar", "closeCalendar"],
  setup(props, { emit }) {
    const flatPickerFormat = props.format
      .replace("A", "K")
      .replace("YYYY", "Y")
      .replace("MM", "m")
      .replace("DD", "d")
      .replace("HH", "H")
      .replace("mm", "i")
      .replace("ss", "S");

    const state = reactive({
      date: computed(() => {
        return typeof props.date === "string"
          ? props.date
          : props.date.format(props.format);
      }),
    });

    const actions = {
      updateDate: (selectedDates) => {
        if (props.isDeletable) {
          emit("updateDate", selectedDates[0]);
        } else {
          if (selectedDates[0]) {
            emit("updateDate", selectedDates[0]);
          }
        }
      },
      openCalendar: (instance) => {
        if (props.preventInputMinuteElement) {
          // note 시간 값을 직접 입력시 막기
          instance.minuteElement.addEventListener("keydown", (event) => {
            if (event.keyCode >= 48 && event.keyCode <= 57) {
              event.preventDefault();
            }
          });
        }
        emit("openCalendar");
      },
      closeCalendar: () => {
        emit("closeCalendar");
      },
    };

    return { state, actions, flatPickerFormat };
  },
};
</script>

<style scoped>
.date-picker {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
  display: block;
  width: 100%;
}

.date-picker.error {
  border-color: #ff4646;
}
</style>
